<template >
<m-bottom-sheet v-model="showIframe" fullscreen v-if="showIframe" >
        <template #title>{{}}</template>
        <template #body>
            <iframe 
            ref="iframe"
            :src = "source"
            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
            @load="OnLoadIframe"></iframe>
            <div class="d-flex justify-content-center justify-content-center">
                <div class="spinner-border" v-if="isLoading">
                </div>
            </div>
        </template>
    </m-bottom-sheet>
</template>

<script setup>

import { ref, watch} from "vue";
import MBottomSheet from "mobile.vue.components.BottomSheet.vue";
import { showIframe, source } from "mobile.controls.IFrameBottomSheet.ts";


const iframe = ref();

const isLoading = ref(true)



watch(showIframe, () => {

   if(showIframe.value){

    isLoading.value = true;

   }
   else{

    isLoading.value = false;

   }
})


function OnLoadIframe(){

    if(isLoading.value){

    isLoading.value = false
    }

}

</script>